const TOKEN = '@pdiOnline-token-auth';
const TOKEN_ADMIN = '@pdiOnline-atoken-auth';
const REFRESH = '@pdiOnline-refreshToken';
const USER_DATA = '@pdiOnline-user';

export const STORAGE = {
    TOKEN,
    TOKEN_ADMIN,
    REFRESH,
    USER_DATA
}