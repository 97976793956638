import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IFilterFilesVehicles } from 'src/context/hooks/useFileRequest';
import { IFilterRequest, IRequestVehicleData } from 'src/context/hooks/useListRequest';
import { ITypeNewRequest } from '../../context/hooks/useRequest';

interface IFilterCargaAndArquivedStatus {
  client?: string;
}

export class Requests {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getRequests(filter?: IFilterRequest | undefined) {
    return this.instance.get('/request', {
      params: filter,
    } as AxiosRequestConfig);
  }

  getRequest(id: string) {
    return this.instance.get(`/request/${id}`);
  }

  getRequestsVehiclesTable(idRequest: string, filter: any = null) {
    return this.instance.get(`/request/${idRequest}/vehicles`, {
      params: filter,
    } as AxiosRequestConfig);
  }

  getRequestsFilesTable(idRequest: string, filter?: IFilterFilesVehicles) {
    return this.instance.get(`request/${idRequest}/files`, {
      params: filter,
    } as AxiosRequestConfig);
  }

	setNewFileRequest(idRequest: string, data: any) {
		return this.instance.patch(`request/${idRequest}/files`, data)
	}

  createLote(idRequest: string, data: any) {
    return this.instance.post(`request/${idRequest}/batch`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '',
      },
      handlerEnabled: true,
    });
  }

  listLotes(idRequest: string) {
    return this.instance.get(`request/${idRequest}/batch`);
  }

  getRequestsServices() {
    return this.instance.get('/global/services');
  }

  getRequestsModels(clientId: string, ids: string[]) {
    return this.instance.post(`/global/models/${clientId}`, ids);
  }

  addModelService(clientId: string, service: string, model: string) {
    return this.instance.post(`/global/models/${service}/${clientId}`, {
      name: model,
    });
  }

  setNewVehicles(idRequest: string, values: IRequestVehicleData) {
    return this.instance.post<any[]>(`/request/${idRequest}/vehicles`, values);
  }

  getVehicle(idRequest: string, idVehicle: string) {
    return this.instance.get(`/request/${idRequest}/vehicles/${idVehicle}`);
  }

  deleteVehicle(idRequest: string, idVehicle: string) {
    return this.instance.delete(`/request/${idRequest}/vehicles/${idVehicle}`);
  }

  newRequestPDI(values: ITypeNewRequest) {
    return this.instance.post('/request', values);
  }

    setStatusRequest(idRequest: string) {
        return this.instance.patch(`/request/${idRequest}/status`);
	}

	setStatusPendingIssues(idRequest: string, data: {status: number, vehicles: string[]}) {
        return this.instance.patch(`/request/${idRequest}/status`, data);
	}

  updateRequest(idRequest: string, data: { vehicles: string[]; description: string }) {
    return this.instance.put(`/request/${idRequest}`, data);
  }

  deleteFile(idRequest: string, idFile: string) {
    return this.instance.delete(`/request/${idRequest}/files/${idFile}`);
  }

  getVehiclesStatusCarga(filter: IFilterCargaAndArquivedStatus) {
    return this.instance.get('/vehicles/finalizado', {
      params: filter,
    } as AxiosRequestConfig);
  }

  vehicleArquived(values: any) {
    return this.instance.post('/vehicles/arquivado', values);
  }

  getVehiclesStatusArquived(filter: IFilterCargaAndArquivedStatus) {
    return this.instance.get('/vehicles/arquivado', {
      params: filter,
    } as AxiosRequestConfig);
  }

  getVehiclesDispatched(filter: IFilterCargaAndArquivedStatus) {
    return this.instance.get('/vehicles/expedido', {
      params: filter,
    } as AxiosRequestConfig);
  }

  getLote(idRequest: string, idLote: string) {
    return this.instance.get(`/request/${idRequest}/batch/${idLote}`);
  }

  getLoteDownload(idRequest: string, idLote: string) {
    // @ts-ignore
    return this.instance.get(`/request/${idRequest}/batch/${idLote}/download`, {
      responseType: 'blob',
    });
  }

  deleteRequest(idRequest: string) {
    return this.instance.delete(`/request/${idRequest}`);
  }

  getVehiclesDownload(idRequest: string) {
    // @ts-ignore
    return this.instance.get(`/request/${idRequest}/download-daily`, { responseType: 'blob' });
  }

	setStatusLote(id: string) {
		return this.instance.patch(`/vehicles/arquivado/${id}`);
	}
}

const _Requests = (instance: AxiosInstance) => new Requests(instance);

export default _Requests;
