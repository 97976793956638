import React, { lazy } from 'react';
import RoutesEnum from 'src/enum/RoutesEnum';

export const authGuardAdminRoutes = [
  {
    exact: true,
    path: RoutesEnum.ADMIN_DASHBOARD_ROUTE,
    component: lazy(() => import('src/pages/dashboard')),
  },
  {
    exact: true,
    path: RoutesEnum.REQUEST_PAGE_ADMIN_ROUTE,
    component: lazy(() => import('src/pages/vehiclesListSadaPage')),
  },
  {
    exact: true,
    path: RoutesEnum.STOCK_PAGE_ADMIN_ROUTE,
    component: lazy(() => import('src/pages/stockPage')),
  },
  {
    exact: true,
    path: RoutesEnum.DISAPPROVE_VEHICLES_PAGE_ADMIN_ROUTE,
    component: lazy(() => import('src/pages/vehiclesDisapproveSadaPage')),
  },
  {
    exact: true,
    path: RoutesEnum.MANAGE_REVIEWS_ADMIN_ROUTE,
    component: lazy(() => import('src/pages/platform-rating')),
  },
  {
    exact: true,
    path: RoutesEnum.VIEW_CLIENT_ROUTE,
    component: lazy(() => import('src/pages/clientsPage')),
  },
  {
    exact: true,
    path: RoutesEnum.EDIT_CLIENT_ROUTE,
    component: lazy(() => import('src/pages/clientsPage/views/editClient')),
  },
  {
    exact: true,
    path: RoutesEnum.ADD_USER_ROUTE,
    component: lazy(() => import('src/pages/clientsPage/views/addUser')),
  },
  {
    exact: true,
    path: RoutesEnum.VIEW_TEAM_ROUTE,
    component: lazy(() => import('src/pages/teamPage')),
  },
  {
    exact: true,
    path: RoutesEnum.EDIT_USER_ROUTE,
    component: lazy(() => import('src/pages/clientsPage/views/editUser')),
  },
  {
    exact: true,
    path: RoutesEnum.SERVICE_ROUTE,
    component: lazy(() => import('src/pages/servicePage')),
  },
  {
    exact: true,
    path: RoutesEnum.SERVICE_EDIT_ROUTE,
    component: lazy(() => import('src/pages/servicePage/view/editService')),
  },
]
