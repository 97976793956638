import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IFilterRequest } from 'src/context/hooks/useListRequest';
import { ITypeNewRequest } from '../../context/hooks/useRequest';

interface IValueDispatched {
  vehicles: string[];
}

class RequestsSada {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getRequests(filter?: IFilterRequest | undefined) {
    return this.instance.get('/admin/request', {
      params: filter,
    } as AxiosRequestConfig);
  }

  getRequest(id: string) {
    return this.instance.get(`/admin/request/${id}`);
  }

  newRequestPDI(values: ITypeNewRequest) {
    return this.instance.post('/request', values);
  }

  updateRequest(idRequest: string, data: { vehicles: string[]; description: string }) {
    return this.instance.put(`/request/${idRequest}`, data);
  }

  setRequestDispatched(idRequest: string, values: IValueDispatched) {
    return this.instance.patch(`/admin/request/${idRequest}/expedicao`, values);
  }

  dispatchedVehicles(values: any) {
    return this.instance.post('/admin/vehicles/expedido', values);
  }

  listLotes(idRequest: string) {
    return this.instance.get(`request/${idRequest}/batch`);
  }

  setVehicleArquived(idRequest: string, values: IValueDispatched) {
    return this.instance.patch(`/admin/request/${idRequest}/arquivado`, values);
  }

  setStatusLote(idRequest: string, idLote: string, values: any) {
    return this.instance.patch(`/admin/request/${idRequest}/batch/${idLote}`, values);
  }

  getLote(idRequest: string, idLote: string) {
    return this.instance.get(`/admin/request/${idRequest}/batch/${idLote}`);
  }

  getLoteDownload(idRequest: string | undefined, idLote: string | undefined) {
    // @ts-ignore
    return this.instance.get(`/admin/request/${idRequest}/batch/${idLote}/download`, {
      responseType: 'blob',
    });
  }
}
const _RequestsSada = (instance: AxiosInstance) => new RequestsSada(instance);

export default _RequestsSada;
